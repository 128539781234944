export default async ({ store, app, $axios }) => {
  const chat = process.browser ? JSON.parse(localStorage.getItem('chat')) : null

  if (typeof chat === 'object' && chat !== null && 'id' in chat && 'key' in chat) {
    await $axios.put(`${app.i18n.locale}/${store.state.country.iso_code}/support/chats/${chat.id}`, { key: chat.key })
      .then((res) => {
        store.dispatch('chat/setChat', res.data.data)
        store.dispatch('support/setActive', false)
        store.dispatch('chat/setActive', true)
      })
      .catch((error) => {
        console.log(error)
        localStorage.removeItem('chat')
      })
      // .finally(() => store.dispatch('chat/setChatLoading', false))
  } else {
    // store.dispatch('chat/setChatLoading', false)
  }
}
