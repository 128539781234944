import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

export default ({ app, $auth }, inject) => {
  const config = {
    authEndpoint: `${app.$config.pusherScheme}://${app.$config.pusherHost}/broadcasting/auth`,
    // auth: {
    //   headers: {
    //     Authorization: $auth.strategy.token.get()
    //   }
    // },
    key: app.$config.pusherAppKey,
    cluster: app.$config.pusherAppCluster
  }

  if (app.$config.pusherAppKey === 'local') {
    config.broadcaster = 'reverb'
    config.wsHost = app.$config.pusherHost
    config.wsPort = app.$config.pusherPort
    config.wssPort = app.$config.pusherPort
    config.forceTLS = app.$config.pusherScheme === 'https'
    config.enabledTransports = ['ws', 'wss']
    config.disableStats = true
  } else {
    config.broadcaster = 'pusher'
    config.encrypted = app.$config.pusherScheme === 'https'
  }

  const echo = new Echo(config)

  inject('echo', echo)
}
